import React, { Component } from 'react'
import Container, { TitleBlock } from './pageComponents'
import ssLogo from '../images/simple-saving-icon.png'

class Header extends Component {
  render() {
    return (
      <TitleBlock>
        <div className="advert">
          <p>Advertisement</p>
        </div>
        <div className="pageName">
          <Container>
            <img src={ssLogo} alt="Simple Savings Icon" />
            <h1>Simple Savings</h1>
          </Container>
        </div>
        <div className="breadcrumbs">
          <Container>
            <p>Home › Finances › Mortgage › Home Refinance</p>
          </Container>
        </div>
      </TitleBlock>
    )
  }
}

// Header.propTypes = {   siteTitle: PropTypes.string, } Header.defaultProps = {
//   siteTitle: ``, }

export default Header
