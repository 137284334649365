import React from "react";
import styled from "styled-components";

const Container = styled.div`
  width: 94%;
  max-width: 720px;
  margin: 0 auto;
  .textLink {
    color: #1e88e5;
    text-decoration: none;
    &:hover {
      text-decoration: underline;
    }
  }
  .jackpot {
    margin: 0;
    padding: 0;
    line-height: 1;
    font-size: 16px;
  }
  .strong {
    font-weight: 700;
  }
  hr {
    margin: 5px 0;
    background: #333;
  }
`;

const TitleBlock = styled.div`
  width: 100%;
  background: #344a5f;
  color: #fff;
  padding: 0;
  .pageName {
    height: 58px;
    @media (max-width: 801px) {
      height: 36px;
    }
  }
  img {
    float: left;
    width: 50px;
    margin-right: 5px;
    margin-bottom: 0;
    @media (max-width: 801px) {
      width: 32px;
    }
  }
  .advert {
    width: 100%;
    text-align: center;
    background: #273c51;
    color: #a4a4a4;
    font-family: "Open Sans";
    margin-bottom: 15px;
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
  }
  h1 {
    font-size: 50px;
    line-height: 55px;
    @media (max-width: 801px) {
      font-size: 32px;
      line-height: 34px;
    }
  }
  .breadcrumbs {
    width: 100%;
    background: #fff;
    color: #5c6270;
    padding: 10px 0;
    border-bottom: 1px solid #dedede;
    margin-bottom: 15px;
    p {
      font-size: 14px;
      line-height: 20px;
      margin: 0;
      padding: 0;
    }
  }
`;

const ImageContainer = styled.div`
  width: 100%;
  margin: 20px 0 10px;
  padding: 0;
  img {
    width: 100%;
  }
`;
const hrefLink = "http://advance-klestand.com/click";
const hrefImgLink = "http://financehacks.net/?flux_action=1&s1=[audience]_old&s2=[ad]";

const AgeButtonLink = ({ className, link, children }) => (
  <a className={className} href={link} target="_blank" rel="noopener noreferrer">
    {children}
  </a>
);

const AgeButton = styled(AgeButtonLink)`
  width: 48%;
  background: #1e88e5;
  border: 2px solid #333;
  border-radius: 10px;
  height: 60px;
  box-shadow: 3px 3px 6px #999;
  margin-bottom: 15px;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 38px;
  font-weight: 700;
  text-decoration: none;
  transition: background 0.3s ease-in-out;
  &:hover {
    background: #1565c0;
  }

  @media (max-width: 801px) {
    font-size: 24px;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  max-width: 550px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

const FooterBlock = styled.div`
  width: 100%;
  background: #2e3138;
  color: #828282;
  padding: 20px 0;
  margin-top: 30px;
  font-size: 14px;
  line-height: 1.2;
  .center {
    text-align: center;
    margin: 10px auto 0;
  }
`;

export default Container;
export { TitleBlock, ImageContainer, AgeButton, ButtonContainer, hrefLink, hrefImgLink, FooterBlock };
